import { useState } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';
import Link from 'next/link';
import Config from '../config/Config';
import DropdownMenu from './AvatarDropDown';
import styles from '../styles/Navbar.module.css';
import Avatar from './Avatar';
import Button from './Button';
import { useUser, ROLES, useAuth } from '../providers/Auth';
import { useFeatureHub } from '../providers/FeatureHub';
import { useApp } from '../providers/App';
import ModalStatus from '../const/modal';

const latestItems = [
  {
    icon: '/content.svg',
    text: 'News',
    link: `${Config.WORDPRESS_URL}/news`,
    width: 20,
    height: 20,
    target: '',
  },
  {
    icon: '/podcast.svg',
    text: 'Podcasts',
    link: `${Config.WORDPRESS_URL}/podcasts/`,
    width: 20,
    height: 20,
    target: '',
  },
];

export default function Navbar() {
  const [showMenu, setShowMenu] = useState(false);
  const [showNavMenu, setShowNavMenu] = useState(false);
  const [showLatest, setShowLatest] = useState(false);

  const { features } = useFeatureHub();
  const { setGlobalModalStatus } = useApp();
  const { hasRole } = useAuth();
  const { showReleases } = features;
  const user = useUser();
  const router = useRouter();
  function isActivePage(route: string, itStartsWith = false): string {
    if (!itStartsWith && router?.asPath === route) {
      return styles.headerLinkActive;
    }

    if (itStartsWith && router?.asPath.startsWith(route)) {
      return styles.headerLinkActive;
    }

    return '';
  }

  return (
    <div data-testid="navbar" className="relative z-10">
      <nav className={styles.container}>
        {/* Hamburger Icon */}
        {user && (
          <>
            <div
              role="button"
              tabIndex={-1}
              className={styles.hamburgerIcon}
              onClick={() => setShowNavMenu(!showNavMenu)}
              onKeyPress={() => setShowNavMenu(!showNavMenu)}
            >
              <svg
                width="34"
                height="3"
                viewBox="0 0 34 3"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={
                  showNavMenu
                    ? 'transition duration-300 ease-in-out transform rotate-45 origin-center translate-x-px'
                    : 'transition duration-300 ease-in-out'
                }
              >
                <path d="M1 1.98242H33" stroke="#424242" strokeWidth="2" strokeLinecap="round" />
              </svg>
              <svg
                width="34"
                height="3"
                viewBox="0 0 34 3"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={showNavMenu ? 'hidden' : 'transition duration-300 ease-in-out'}
              >
                <path d="M1 1.98242H33" stroke="#424242" strokeWidth="2" strokeLinecap="round" />
              </svg>
              <svg
                width="34"
                height="3"
                viewBox="0 0 34 3"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={
                  showNavMenu
                    ? 'transition duration-300 ease-in-out transform -rotate-45 origin-center -translate-y-1.5'
                    : 'transition duration-300 ease-in-out'
                }
              >
                <path d="M1 1.98242H33" stroke="#424242" strokeWidth="2" strokeLinecap="round" />
              </svg>
            </div>
          </>
        )}
        <div className={`${user ? '' : styles.logo} ${styles.leftLogo}`}>
          <Link href={Config.APP_URL || Config.WORDPRESS_URL}>
            <a className="flex" data-testid="logo">
              <Image src="/ss_logo.svg" alt="SoleSavy Logo" width={112} height={40} />
            </a>
          </Link>
        </div>

        {/* Todo: Refactor - Should not have to wrap this block with the condition if a user exist, that logic should happen before the render */}
        {user && (
          <>
            <ul className={styles.headerLinks}>
              <li>
                <Link href={Config.APP_URL || Config.WORDPRESS_URL}>
                  <a
                    className={isActivePage('/')}
                    data-testid="dashboard"
                    tabIndex={0}
                    onFocus={() => setShowMenu(false)}
                    href={Config.APP_URL || Config.WORDPRESS_URL}
                  >
                    Dashboard
                  </a>
                </Link>
              </li>
              {showReleases && hasRole(ROLES.calendar) && (
                <li>
                  <Link href="/releases">
                    {/* eslint-disable */}
                    <a
                      className={isActivePage('/release', true)}
                      data-testid="releases"
                      tabIndex={0}
                      onFocus={() => setShowMenu(false)}
                      href="/releases"
                    >
                      Releases
                    </a>
                    {/* eslint-enable */}
                  </Link>
                </li>
              )}
              <li
                className="cursor-pointer"
                onMouseEnter={() => setShowMenu(true)}
                onFocus={() => setShowMenu(true)}
              >
                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
                <div className="relative" onMouseLeave={() => setShowMenu(false)} tabIndex={0}>
                  <a>
                    Latest
                    <span className="pl-2">
                      <Image src="/chevron_down.svg" alt="Chevron down" width={13} height={8} />
                    </span>
                  </a>
                  <DropdownMenu offset="center" isVisible={showMenu} menuItems={latestItems} />
                </div>
              </li>
              {/* <li>
                <a onFocus={() => setShowMenu(false)} href={`${Config.WORDPRESS_URL}/events/`}>
                  Events
                </a>
              </li> */}
              <li>
                <a
                  href={hasRole(ROLES.education) ? Config.EDUCATION_CENTER : '#upgrade'}
                  target="_blank"
                  onFocus={() => setShowMenu(false)}
                  onClick={(e) => {
                    if (!hasRole(ROLES.education)) {
                      e.preventDefault();
                      setGlobalModalStatus(ModalStatus.Upgrade);
                    }
                  }}
                  data-testid="education-center"
                  rel="noreferrer"
                >
                  Education Center
                </a>
              </li>
            </ul>
            <div className="flex gap-6">
              {hasRole(ROLES.upgradable) && (
                <Button
                  btnContext="Upgrade"
                  secondary
                  border
                  canActivate
                  handleClick={() => setGlobalModalStatus(ModalStatus.Upgrade)}
                />
              )}
              <Avatar userName={user.firstName} />
            </div>
          </>
        )}
      </nav>

      {/* hamburger menu  */}
      <div className={`${showNavMenu ? 'flex' : 'hidden'} ${styles.hamburgerMenu}`}>
        <Link href={Config.APP_URL || Config.WORDPRESS_URL}>
          <a
            className={isActivePage('/')}
            data-testid="dashboard"
            tabIndex={0}
            href={Config.APP_URL || Config.WORDPRESS_URL}
            onClick={() => setShowNavMenu(!showNavMenu)}
          >
            Dashboard
          </a>
        </Link>
        {showReleases && hasRole(ROLES.calendar) && (
          <Link href="/releases">
            {/* eslint-disable */}
            <a
              className={isActivePage('/releases', true)}
              data-testid="releases"
              tabIndex={0}
              onFocus={() => setShowMenu(false)}
              href="/releases"
              onClick={() => setShowNavMenu(!showNavMenu)}
            >
              Releases
            </a>
            {/* eslint-enable */}
          </Link>
        )}
        <div className="text-lg font-normal relative">
          <div
            onClick={() => setShowLatest(!showLatest)}
            role="button"
            data-testid="latest"
            tabIndex={0}
            onKeyDown={() => setShowLatest(!showLatest)}
            className={`flex gap-2 items-center py-3 focus:outline-none ${
              showLatest ? 'border-b border-gray-300' : ''
            } cursor-pointer`}
          >
            <span>Latest</span>
            <span>
              <svg
                width="13"
                height="8"
                viewBox="0 0 13 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={
                  showLatest ? 'transform -rotate-180 duration-100' : 'transform duration-100'
                }
              >
                <path d="M1.68213 1L6.68213 6L11.6821 1" stroke="#757575" strokeWidth="2" />
              </svg>
            </span>
          </div>
          {showLatest && (
            <div>
              {latestItems.map((item) => (
                <div className="flex py-3 border-b border-gray-300" key={item.text}>
                  <div className="flex pr-3">
                    <Image src={item.icon} alt={item.text} width={20} height={20} />
                  </div>
                  <a href={item.link} rel="noreferrer">
                    {item.text}
                  </a>
                </div>
              ))}
            </div>
          )}
        </div>
        {/* <a href={`${Config.WORDPRESS_URL}/events/`} data-testid="events">
          Events
        </a> */}
        <a href={Config.EDUCATION_CENTER} data-testid="education-center">
          Education Center
        </a>
      </div>
    </div>
  );
}
