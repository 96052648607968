import { useState } from 'react';
import Image from 'next/image';
import styles from '../styles/Avatar.module.css';
import AvatarDropDownMenu from './AvatarDropDown';

interface AvatarProps {
  userName?: string;
  greeting?: boolean;
}

// TODO: This should probably be passed in - refactor opportunity
const accountItems = [
  {
    icon: '/setting.svg',
    text: 'Account',
    link: '/account',
    width: 24,
    height: 24,
    target: '_self',
  },
  {
    icon: '/user.svg',
    text: 'Profile',
    link: '/profile',
    width: 24,
    height: 24,
    target: '_self',
  },
  {
    icon: '/wallet.svg',
    text: 'Membership',
    link: '/membership',
    width: 24,
    height: 24,
    target: '_self',
  },
  {
    icon: '/icons/alert.svg',
    text: 'Get Help',
    link: '/help',
    width: 24,
    height: 24,
    target: '_self',
  },
  { icon: '/logout.svg', text: 'Log Out', link: '/logout', width: 24, height: 24, target: '_self' },
];

export default function Avatar({ userName, greeting }: AvatarProps) {
  const [showMenu, setShowMenu] = useState(false);
  const useDefaultAvatar: boolean = userName === '' || userName === undefined;
  const avatarLetter = userName ? userName.charAt(0).toLocaleUpperCase() : '';

  const showMenuHandler = () => {
    setShowMenu(true);

    setTimeout(() => {
      setShowMenu(false);
    }, 4000);
  };

  return (
    <div
      onMouseEnter={() => setShowMenu(true)}
      className="flex items-center cursor-pointer relative"
    >
      {useDefaultAvatar ? (
        <div className="min-w-max">
          <Image
            src="/icons/default_avatar.svg"
            alt="User Avatar"
            width={greeting ? 80 : 40}
            height={greeting ? 80 : 40}
            tabIndex={0}
          />
        </div>
      ) : (
        <div className={greeting ? styles.greeting : styles.circle}>
          <span
            data-testid="avatarLetter"
            className={`text-white font-bold font-tommyBold ${greeting ? 'text-5xl' : 'text-2xl'}`}
          >
            {avatarLetter}
          </span>
        </div>
      )}

      {/* TODO: Refactor this out of this component and pass it in as a child prop */}
      {!greeting ? (
        <div onMouseEnter={() => showMenuHandler()}>
          <span className="pl-3" tabIndex={0} role="button" onKeyPress={() => showMenuHandler()}>
            <Image src="/chevron_down.svg" alt="Chevron down" width={13} height={8} />
          </span>
          <div onMouseLeave={() => setShowMenu(false)}>
            <AvatarDropDownMenu offset="right" isVisible={showMenu} menuItems={accountItems} />
          </div>
        </div>
      ) : null}
    </div>
  );
}

Avatar.defaultProps = {
  greeting: false,
  userName: '',
};
